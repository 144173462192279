.products {
  padding: 140px 0 0;

  @media (max-width: 960px) {
    padding: 110px 0 0;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
  }
}

.products-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 24px;
  }

  &__item {
    width: calc(50% - 6px);
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid transparent;

    &:hover {
      border: 2px solid #f66b27;

      .button-main {
        display: flex;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 80px 40px 40px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));

    .button-main {
      margin-top: 16px;
      display: none;
    }

    @media (max-width: 768px) {
      padding: 80px 16px 32px;
    }
  }

  &__title {
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    line-height: 30px;
    max-width: 375px;

    @media (max-width: 960px) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }

    &--price {
      font-size: 24px;
      margin-top: 6px;

      @media (max-width: 768px) {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
}