.product-info {

  .order-button {
    margin: 24px 0 120px;

    @media (max-width: 600px) {
      margin: 32px 0;
    }
  }

  .product-count {
    margin: 32px 0 24px;

    @media (max-width: 960px) {
      margin: 24px 0;
    }

    @media (max-width: 768px) {
      margin: 16px 0;
    }
  }

  .title {
    @media (max-width: 960px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
}