.advantages {
  padding: 140px 0;

  @media (max-width: 960px) {
    padding: 110px 0;
  }

  @media (max-width: 768px) {
    padding: 80px 0;
  }

  &__text {
    font-size: 28px;
    font-weight: 400;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 22px;
      text-align: left;
    }
  }

  &__list {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 80px 12px;
    list-style: none;
    margin-top: 100px;

    @media (max-width: 960px) {
      flex-wrap: wrap;
    }

    @media (max-width: 550px) {
      gap: 50px 12px;
    }
  }

  &__list-item {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 18px;
    line-height: 1.3;
    width: 100%;

    img {
      margin-bottom: 24px;
    }

    p:last-child {
      margin-top: 8px;
    }

    @media (max-width: 960px) {
      width: calc(33.3% - 8px);
    }

    @media (max-width: 550px) {
      width: calc(50% - 6px);
    }
  }
}