.product-description {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0;

  &.opened {
    .product-description {
      &__arrow {
        transform: rotate(0);
      }
    }
  }

  &__header {
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      flex-grow: 1;
    }
  }

  &__arrow {
    width: 24px;
    height: 24px;
    background: none;
    border: none;
    cursor: pointer;
    transform: rotate(180deg);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 12px;
    align-items: flex-start;

    ul,
    p {
      color: rgba(0, 0, 0, 0.75);
      font-size: 14px;
      font-weight: 400;
      font-family: Inter, sans-serif;
      line-height: 20px;
    }

    a {
      color: rgba(17, 112, 255, 0.75);
      font-size: 14px;
      font-weight: 400;
      font-family: Inter, sans-serif;
      line-height: 20px;
    }

    ul {
      padding-left: 18px;
    }
  }
}