.support-button {
  position: relative;

  &.mobile {
    .support-button {
      &__btn {
        display: none;
      }

      &__menu {
        position: static;
        height: auto;
        visibility: visible;
        width: auto;
        padding: 0;
      }

      &__menu-content {
        padding: 0;
        border: none;
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: center;
      }

      &__divider {
        display: none;
      }

      &__menu-item {
        padding: 0;
        display: block;

        span {
          display: none;
        }
      }

      &__label {
        width: 100%;
        padding: 0;
      }
    }
  }

  &:hover {
    .support-button__menu {
      //height: 286px;
      height: 238px;
      visibility: visible;
    }
  }

  &__btn {
    font-family: Unbounded, sans-serif;
    background: none;
    border-radius: 6px;
    border: 1px solid #F66B27;
    color: #F66B27;
    font-size: 14px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-weight: 400;
    max-width: 150px;
    padding: 11px 10px;

    @media (max-width: 560px) {
      width: 40px;

      span {
        display: none;
      }
    }
  }

  &__menu {
    border: 1px solid transparent;
    visibility: hidden;
    border-radius: 16px;
    overflow: hidden;
    position: absolute;
    right: 0;
    width: 282px;
    z-index: 2;
    top: 100%;
    transition: .2s;
    height: 0;
    padding: 8px 0 0;
  }

  &__menu-content {
    border: 1px solid #D0D5DD;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    padding: 14px 0 24px;
  }

  &__menu-item {
    font-family: Inter, sans-serif;
    display: flex;
    gap: 12px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    padding: 4px 20px;
    cursor: pointer;
    text-decoration: none;
    transition: .3s;

    &:hover {
      background: rgba(0, 0, 0, 0.03);
    }
  }

  &__social-btn {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    background: rgba(18, 18, 18, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__divider {
    width: calc(100% - 40px);
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 14px auto 8px;
  }

  &__label {
    font-family: Inter, sans-serif;
    text-align: center;
    color: #7A7A7A;
    font-size: 14px;
    font-weight: 400;
    padding: 0 20px;
  }
}