.select {
  position: relative;
  width: 100%;

  &:focus {
    .select__menu {
      display: block;
    }
  }

  &__field {
    width: 100%;
    border: 1px solid #5f5f5f;
    border-radius: 8px;
    padding: 14px 32px 14px 16px;
    outline: none;
    font-size: 14px;
    font-family: Inter, sans-serif;
    appearance:none;
    background: transparent;
    position: relative;
    z-index: 2;
    overflow: hidden;
    height: 100%;

    span {
      color: #5f5f5f;
    }
  }

  &__menu {
    position: absolute;
    background: #fff;
    z-index: 99;
    border-radius: 12px;
    width: 100%;
    top: calc(100% + 5px);
    overflow: hidden;
    display: none;
    border: 1px solid #5f5f5f;

    div {
      padding: 16px;
      color: #121212;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      font-family: Inter, sans-serif;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, 0.08);
      }
    }
  }

  &__arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    z-index: 1;

    svg {
      width: 20px;
    }
  }

  &.error {
    .select {
      &__field {
        border-color: red;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: -16px;
    color: red;
    font-size: 13px;
    left: 16px;
    font-family: Inter, sans-serif;
  }
}

.input {
  width: 100%;
  position: relative;

  &__field {
    width: 100%;
    border: 1px solid #5f5f5f;
    border-radius: 8px;
    padding: 14px 16px;
    outline: none;
    font-size: 14px;
    font-family: Inter, sans-serif;
    background: transparent;
    position: relative;
    overflow: hidden;
    resize: none;

    &::placeholder {
      color: #5f5f5f;
    }
    &::-moz-placeholder {
      color: #5f5f5f;
    }
    &::-ms-input-placeholder {
      color: #5f5f5f;
    }
    &::-webkit-input-placeholder {
      color: #5f5f5f;
    }
  }

  &.error {
    .input {
      &__field {
        border-color: red;
      }
    }
  }

  &__error {
    position: absolute;
    bottom: -16px;
    color: red;
    font-size: 13px;
    left: 16px;
    font-family: Inter, sans-serif;
  }
}

.form {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  &__block {
    display: flex;
    gap: 16px;
    width: 100%;

    @media (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__order-block {
    display: flex;
    gap: 16px;
    width: 100%;
    flex-wrap: wrap;
    padding: 15px 0;
    border-top: 1px solid #5f5f5f;
    justify-content: space-between;
  }

  &.dark {
    .select {
      &:after {
        content: '';
        background: #343434;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 8px;
      }

      &__field {
        color: #fff;

        span {
          color: rgba(255, 255, 255, 0.75);
        }
      }

      &__arrow {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }

    .input {
      &__field {
        color: #fff;
        background: #343434;

        &::placeholder {
          color: rgba(255, 255, 255, 0.75);
        }
        &::-moz-placeholder {
          color: rgba(255, 255, 255, 0.75);
        }
        &::-ms-input-placeholder {
          color: rgba(255, 255, 255, 0.75);
        }
        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.75);
        }
      }
    }
  }
}