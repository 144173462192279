.product-page {
  .menu {
    position: static;
    transform: none;
    margin: 16px auto 0;
  }
}

.product-video {
  padding: 140px 0 0;

  @media (max-width: 960px) {
    padding: 110px 0 0;
  }

  @media (max-width: 768px) {
    padding-top: 80px;
  }

  &__video-wrapper {
    margin-top: 40px;
    border-radius: 8px;
    overflow: hidden;

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    iframe {
      width: 100%;
      height: 500px;
      display: block;

      @media (max-width: 960px) {
        height: 400px;
      }

      @media (max-width: 768px) {
        height: 320px;
      }

      @media (max-width: 480px) {
        height: 240px;
      }
    }
  }
}

.product-more {
  padding: 140px 0;

  @media (max-width: 960px) {
    padding: 110px 0;
  }

  @media (max-width: 768px) {
    padding: 80px 0;
  }

  .slider {
    margin-top: 40px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    .slick-dots {
      bottom: -40px;
    }
  }

  &__item {
    padding: 0 16px 0 0;
    display: block !important;
    outline: none;
    text-decoration: none;

    @media (max-width: 480px) {
      padding: 0;
    }
  }

  &__title {
    font-size: 20px;
    line-height: 25px;
    max-width: 300px;
    color: #171717;
    font-weight: 400;
    margin-top: 16px;

    @media (max-width: 960px) {
      font-size: 16px;
      line-height: 1.2;
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 1.2;
    }
  }

  &__img {
    width: 100%;
    outline: none;
    border-radius: 10px;
    display: block;
  }
}

.product-card {
  padding-top: 40px;
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    padding-top: 16px;
    flex-direction: column;
    gap: 50px;
  }

  & > div {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }

    &:first-of-type {
      max-width: 600px;

      @media (max-width: 768px) {
        max-width: none;
      }
    }
  }

  .slick-thumb {
    position: static;
    gap: 8px;
    display: flex !important;
    margin-top: 16px;

    li {
      width: 25%;
      margin: 0;
      border-radius: 10px;
      overflow: hidden;
      height: auto;
      border: 1px solid transparent;
      transition: 0.3s;

      &.slick-active {
        border-color: #FF6D26;
      }
    }

    a {
      display: block;
    }

    img {
      width: 100%;
      display: block;
    }
  }

  &__slide {
    display: block !important;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    outline: none;

    img {
      width: 100%;
      display: block;
      outline: none;
    }
  }

  &__slide-num {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #b9b9b9;
    border-radius: 100px;
    background: #fff;
    padding: 4px 10px;
    font-family: Inter, sans-serif;
    font-size: 14px;

    span {
      color: rgba(0, 0, 0, 0.75);
    }
  }

  &__arrow {
    width: 44px;
    height: 44px;
    background: #fff;
    border: 1px solid #B9B9B9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 228px;
    left: 16px;
    z-index: 1;

    @media (max-width: 1024px) {
      top: 190px;
    }

    @media (max-width: 920px) {
      top: 150px;
    }

    path {
      transition: 0.3s;
    }

    &:hover {
      path {
        stroke: #ff6d26;
      }
    }

    &--next {
      left: auto;
      right: 16px;
    }
  }
}
