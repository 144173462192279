.product-count {
  max-width: 254px;
  width: 100%;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    max-width: none;
  }

  &__price {
    color: #171717;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    white-space: nowrap;
  }

  &__counter {
    display: flex;
    height: 32px;
    align-items: center;

    button {
      border: 1px solid #EBEBEB;
      background: none;
      width: 32px;
      height: 100%;
      font-size: 20px;

      &:not(:disabled):hover {
        color: #ff6d26;
        cursor: pointer;
      }

      &:first-of-type {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-of-type {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    div {
      padding: 0 10px;
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 40px;
      font-size: 14px;
      font-family: Inter, sans-serif;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.75);
    }
  }
}