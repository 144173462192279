.header {
  background: #292929;
  width: 100%;

  @media (max-width: 768px) {
    padding-top: 60px;
  }

  &__content {
    width: 100%;
    max-width: 596px;
    padding: 260px 0 60px;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
      padding: 45px 0 80px;
      max-width: none;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 22px;
    color: rgba(240, 241, 242, 0.6);
    margin: 24px 0 36px;
    font-family: Inter, sans-serif;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 18px;
      margin: 16px 0 32px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    gap: 32px;
    margin-bottom: 150px;

    @media (max-width: 768px) {
      margin: 0;
      gap: 24px;

      .button-main {
        max-width: none;
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__media-button {
    border: 1px solid #fff;
    border-radius: 6px;
    background: none;
    transition: 0.3s;

    &:hover {
      border-color: #f66b27;
    }
  }

  &__bg-img {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 573px;

    @media (max-width: 1366px) {
      //max-width: 900px;
    }

    @media (max-width: 960px) {
      max-width: 450px;
    }

    @media (max-width: 768px) {
      position: relative;
      width: 100%;
      order: -1;
      margin: 0 auto;
      max-width: 344px;
    }
  }

  &__container {
    overflow: hidden;
    position: relative;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }
}