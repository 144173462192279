.contacts {
  padding: 80px 0 0;
  background: #292929;

  @media (max-width: 960px) {
    padding: 60px 0 0;
  }

  @media (max-width: 768px) {
    padding: 40px 0 0;
  }

  &__text {
    font-family: Inter, sans-serif;
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    line-height: 18px;
    margin: 8px 0 24px;
    max-width: 375px;
    font-weight: 400;

    &--middle {
      margin: 21px 0 0;
      max-width: 300px;
    }

    &--bottom {
      margin: 0;
      max-width: none;
    }

    @media (max-width: 768px) {
      max-width: none;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    gap: 60px;
    padding-bottom: 140px;

    @media (max-width: 768px) {
      padding-bottom: 80px;
    }

    & > div {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }

      &:last-of-type {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 768px) {
          display: block;
        }
      }
    }
  }

  &__middle {
    padding: 40px 0;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      padding: 32px 16px;
      gap: 32px;
    }
  }

  &__bottom {
    padding: 20px 0;
  }

  &__logo {
    width: 100%;
    max-width: 286px;

    @media (max-width: 960px) {
      max-width: 250px;
    }

    @media (max-width: 768px) {
      max-width: 291px;
    }
  }

  &__container {
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  &__img {
    width: 100%;
    max-width: 612px;
  }

  &__socials {
    display: flex;
    gap: 90px;
    justify-content: flex-end;
    align-items: center;
    padding-right: 38px;
    flex-grow: 1;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding-right: 0;
      gap: 32px;
    }
  }

  &__social-link {
    font-size: 14px;
    font-weight: 400;
    font-family: Inter, sans-serif;
    line-height: 20px;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__block-title {
    color: #fff;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 16px;
  }
}