.about {
  padding: 140px 0;

  @media (max-width: 960px) {
    padding: 110px 0;
  }

  @media (max-width: 768px) {
    padding: 80px 0;
  }

  &__content {
    display: flex;
    gap: 80px;
    align-items: center;

    @media (max-width: 960px) {
      gap: 40px;
    }

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__text {
    color: #5f5f5f;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    font-family: Inter, sans-serif;

    &:first-of-type {
      margin: 24px 0 16px;

      @media (max-width: 768px) {
        margin: 16px 0;
      }
    }
  }

  &__content-left {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      gap: 16px;
    }
  }

  &__circle {
    border-radius: 50%;
    border: 2px solid #ff6d26;
    color: #ff6d26;
    width: 298px;
    height: 298px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    font-weight: 700;

    @media (max-width: 1024px) {
      width: 225px;
      height: 225px;
      font-size: 40px;
    }

    @media (max-width: 768px) {
      width: 185px;
      height: 185px;
      font-size: 32px;
    }

    @media (max-width: 375px) {
      width: 150px;
      height: 150px;
      font-size: 26px;
    }

    &--small {
      width: 225px;
      height: 225px;
      font-size: 36px;

      @media (max-width: 1024px) {
        width: 170px;
        height: 170px;
        font-size: 30px;
      }

      @media (max-width: 768px) {
        width: 140px;
        height: 140px;
        font-size: 24px;
      }

      @media (max-width: 375px) {
        width: 110px;
        height: 110px;
        font-size: 20px;
      }
    }
  }
}