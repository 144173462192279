.application {
  padding: 80px 0;
  background: #F3F6FE;

  @media (max-width: 960px) {
    padding: 60px 0;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
  }

  &__item {
    padding: 0 16px 0 0;
    display: block !important;
    outline: none;
  }

  &__img {
    width: 100%;
    max-width: 510px;
    outline: none;
    border-radius: 10px;
    display: block;

    @media (max-width: 960px) {
      max-width: 430px;
    }

    @media (max-width: 768px) {
      max-width: 320px;
    }

    @media (max-width: 480px) {
      max-width: 240px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 40px;
  }

  .slider {
    margin-top: 40px;

    @media (max-width: 768px) {
      margin-top: 24px;
    }

    @media (max-width: 480px) {
      height: 160px;
      overflow-y: hidden;
    }
  }

  .button-outlined {
    max-width: 70px;
    height: 36px;
    transition: 0.3s;

    svg {
      fill: #ff6d26;
      transition: 0.3s;
    }

    &:hover {
      background: #ff6d26;

      svg {
        fill: #fff;
      }
    }
  }
}