.social-buttons {
  display: flex;
  gap: 16px;

  &__button {
    border: none;
    background: #fff;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
  }
}