body {
  margin: 0;
  padding: 0;
  font-family: Unbounded, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-width: 320px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1272px;
  padding: 0 16px;
  margin: 0 auto;
}

@keyframes rotate {
  0%   {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button-main {
  font-family: Unbounded, sans-serif;
  background: #FF6D26;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-size: 14px;
  width: 100%;
  padding: 16px;
  max-width: 180px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 49.5px;

  &--full-width {
    max-width: none;
  }

  &--small {
    max-width: 140px;
    padding: 11px 16px;
    height: 39.5px;
  }

  &.disabled {
    background: #F0F1F2;
    color: rgba(95, 95, 95, 0.25);
    cursor: default;
  }

  &[disabled] {
    cursor: default;
    pointer-events: none;
  }

  &__loader {
    width: 23px;
    height: 23px;
    animation: rotate 1s linear infinite;
  }
}

.button-outlined {
  font-family: Unbounded, sans-serif;
  background: none;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #F66B27;
  color: #F66B27;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: 400;

  &--small {
    max-width: 150px;
    padding: 11px 10px;
  }
}

.title {
  font-family: Unbounded, sans-serif;
  color: #292929;
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;

  &--light {
    color: #fff;
  }

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
}