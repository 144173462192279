.menu {
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(95, 95, 95, 0.25);
  width: calc(100% - 32px);
  height: 70px;
  position: fixed;
  z-index: 3;
  max-width: 1240px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  gap: 16px;

  @media (max-width: 960px) {
    padding: 18px 16px;
    height: 60px;
    top: 0;
    width: 100%;
    left: 0;
    transform: none;
    right: 0;
    border-radius: 0;
  }

  &__tel {
    color: #ff6d26;
    font-size: 14px;
    text-decoration: none;
    font-weight: 400;
    line-height: 18px;

    &--mobile {
      color: #5f5f5f;
      margin-top: 24px;
    }
  }

  &__logo {
    width: 100%;
    max-width: 274px;
    display: block;

    @media (max-width: 1100px) {
      max-width: 200px;
    }

    @media (max-width: 1000px) {
      max-width: 180px;
    }

    @media (max-width: 768px) {
      max-width: 219px;
    }
  }

  &__menu-button {
    max-width: 78px;
    padding: 11px 13px;

    &--icon {
      width: 36px;
      height: 36px;
    }
  }

  &__content-mobile {
    background: #fff;
    position: absolute;
    left: -1px;
    top: 60px;
    right: -1px;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 16px 40px;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 60px;

    & > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:first-of-type {
        flex-grow: 1;
      }
    }

    .menu-nav {
      margin-bottom: 60px;
    }
  }
}

.menu-nav {
  ul {
    display: flex;
    list-style: none;
    gap: 32px;

    @media (max-width: 1100px) {
      gap: 16px;
    }
  }

  &__link {
    text-decoration: none;
    color: #171717;
    font-size: 14px;
    line-height: 18px;
    position: relative;
    transition: 0.3s;

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: -6px;
      background: transparent;
      display: block;
      transition: 0.3s;
    }

    &:hover {
      color: #ff6d26;

      &::after {
        background: #ff6d26;
      }
    }
  }

  &--mobile {
    ul {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }

    .menu-nav {
      &__link {
        font-size: 32px;
        line-height: 40px;
        color: #5f5f5f;

        &::after {
          display: none;
        }

        &:hover {
          color: #ff6d26;
        }
      }
    }
  }
}